
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

































































































































































































































































































::v-deep {
    .image {
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid lightgray;
    }
}

.is-sticky {
    top: $navbar-height + 1.5rem;
    position: sticky;
}

.text {
  word-break: break-word;
    ::v-deep {
        strong {
            color: inherit
        }
      a {
        span {
          color: inherit !important;
        }
      }
    }

}

.circle {
    $size: 40;
    $indent: $size / 2;

    position: absolute;
    top: calc(50% - #{ $indent }px);
    left: calc(50% - #{ $indent }px);
    z-index: 0;

    width: $size + px;
    height: $size + px;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top-color: #666;
    animation: spin 600ms infinite linear;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
