
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






































































































































































































































































































































































































































































::v-deep {
    .b-table .table-wrapper.has-sticky-header tr:first-child th {
      background-color: white;
    }

    .table-wrapper {
        overflow-x: unset !important;
    }
}

.bulma-button {
    height: 2.5em;
}
