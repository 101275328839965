
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        



































































































.offer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
            "common"
            "aside"
            "others";
}

.common {
    grid-area: common;
}

.aside {
    grid-area: aside;
}

.others {
    grid-area: others;
}

//noinspection SassScssUnresolvedMixin
@include fullhd {
    .offer {
        grid-template-columns: 75% 25%;
        grid-template-areas:
            "common aside"
            "others aside";
    }
}

.columns {
    .column {
        .content {
            > .level {
                align-items: flex-start;
            }
        }
    }
}

.mr-5 {
    margin-right: 5px;
}

::v-deep .asideTable-wrapper {
    .asideTable {
        margin: -0.75em;
    }

    th, td {
        padding: 0.75em;
    }

    tbody {
        font-size: 1.2em;
    }
}

.asideTable {
    ::v-deep td {
        border-top: 1px solid #d5d5d5 !important;
    }
}

ul {
    li {
        list-style: none;
        font-weight: 700 !important;
    }
}

::v-deep {
    .card {
      height: 100%;
    }

    .tag {
        cursor: pointer;
    }

    .content {
        padding: 0.8em 0;
    }
}

.backLink {
    margin: 1em;
}

.card {
    &-header-title {
        font-size: $size-base;
    }
}

.b-tooltip {
    display: flex;
}

.is-sticky {
    position: sticky;
    top: $navbar-height;
    z-index: 1;
}
